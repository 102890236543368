<template>
  <div>
    <section>
      <template>
        <v-container justify="center" align="center">
          <v-row>
            <v-col cols="12">
              <v-card height="900">
                <v-card-title>Verfügbarkeitsprognose</v-card-title>
                <v-card-subtitle
                  >Basierend auf eingegangenden Abwesenheiten</v-card-subtitle
                >
                <v-card-text>
                  <v-sheet height="720" class="rounded-t-lg">
                    <v-toolbar height="64" outlined flat class="rounded-t-lg">
                      <v-btn outlined small @click="prev" class="ml-2 mr-2">
                        <v-icon dense>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn outlined small @click="next" class="mr-4">
                        <v-icon dense>mdi-chevron-right</v-icon>
                      </v-btn>
                      <v-toolbar-title>{{ calendarTitle }}</v-toolbar-title>
                      <div class="flex-grow-1"></div>
                      <v-btn outlined class="mr-4" @click="setToday">
                        Heute
                      </v-btn>
                      <v-menu bottom right>
                        <template v-slot:activator="{ on }">
                          <v-btn outlined v-on="on">
                            <span>{{ typeToLabel[type] }}</span>
                            <v-icon right>mdi-menu-down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="type = 'day'">
                            <v-list-item-title>Tag</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = 'week'">
                            <v-list-item-title>Woche</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = 'month'">
                            <v-list-item-title>Monat</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = '4day'">
                            <v-list-item-title>4 Tage</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-toolbar>
                    <v-calendar
                      ref="calendar"
                      v-model="value"
                      color="primary"
                      :events="events"
                      locale="de"
                      show-week
                      event-category="getEventCategory"
                      show-month-on-first
                      :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                      :short-weekdays="calShortWeekdays"
                      :short-months="calShortMonths"
                      event-name="title"
                      :event-color="getEventColor"
                      :event-margin-bottom="3"
                      :now="today"
                      :type="type"
                      @click:event="showEvent"
                      @click:more="viewDay"
                      @click:date="viewDay"
                      @change="updateRange"
                    >
                      <template v-slot:day-body="{ date, week }">
                        <div
                          class="v-current-time"
                          :class="{ first: date === week[0].date }"
                          :style="{ top: nowY }"
                        ></div>
                      </template>
                    </v-calendar>
                    <v-menu
                      v-model="selectedOpen"
                      :close-on-content-click="false"
                      :activator="selectedElement"
                      max-width="500px"
                      offset-x
                    >
                      <v-card min-width="350px" flat>
                        <!-- <v-toolbar flat :color="selectedEvent.color">
                      <div class="flex-grow-1"></div>
                    </v-toolbar> -->
                        <v-card-title
                          >{{ selectedEvent.title }}
                          <v-spacer></v-spacer>
                          <v-btn
                            :color="$vuetify.theme.dark ? '' : 'secondary'"
                            icon
                            @click="selectedOpen = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-subtitle>
                          <v-icon
                            x-small
                            :color="selectedEvent.type.color"
                            class="mr-1"
                            >mdi-circle</v-icon
                          >
                          {{ selectedEvent.type.title }}
                        </v-card-subtitle>
                        <v-divider></v-divider>
                        <v-card-text>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-clock-start</v-icon>
                            </v-list-item-icon>
                            <v-list-item-subtitle>
                              {{ selectedEvent.duration.start.timestamp }}
                              Uhr
                              <span
                                v-if="selectedEvent.duration.start.isOpen"
                                class="ml-4"
                                style="color: rgb(229, 57, 53)"
                                >Uhrzeit noch offen</span
                              >
                            </v-list-item-subtitle>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-clock-end</v-icon>
                            </v-list-item-icon>
                            <v-list-item-subtitle>
                              {{ selectedEvent.duration.end.timestamp }}
                              Uhr
                              <span
                                v-if="selectedEvent.duration.end.isOpen"
                                class="ml-4"
                                style="color: rgb(229, 57, 53)"
                                >Uhrzeit noch offen</span
                              >
                            </v-list-item-subtitle>
                          </v-list-item>
                          <v-list-item v-if="selectedEvent.location.name">
                            <v-list-item-icon>
                              <v-icon>mdi-map-marker</v-icon>
                            </v-list-item-icon>
                            <v-list-item-subtitle>{{
                              selectedEvent.location.name
                            }}</v-list-item-subtitle>
                          </v-list-item>
                          <v-list-item v-if="selectedEvent.description">
                            <v-list-item-icon>
                              <v-icon>mdi-calendar-text</v-icon>
                            </v-list-item-icon>
                            <v-list-item-subtitle style="white-space: normal">{{
                              selectedEvent.description
                            }}</v-list-item-subtitle>
                          </v-list-item>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn text @click="routeDetails(selectedEvent)">
                            Details
                            <v-icon right>mdi-chevron-right</v-icon></v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-sheet>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
// import Subheader from "@/components/_systemwide/Subheader.vue";

//   import { MY_ORGANIZATION } from "@/store/modules.js";
//   import { GET_SURVEYS } from "@/store/action-types.js";
// import { SCHEDULING_EVENTS_RESPONSES_OWN_update } from "@/data/permission-types.js";
export default {
  name: "availability-forecasts-tab-calendar",
  components: {
    // Subheader,
  },
  data() {
    return {
      today: new Date().toISOString().substr(0, 10),
      focus: new Date().toISOString().substr(0, 10),
      type: "month",
      value: "",
      ready: false,
      typeToLabel: {
        month: "Monat",
        week: "Woche",
        day: "Tag",
        "4day": "4 Tage",
        // "category": "Kategorien"
      },
      title: null,
      start: null,
      end: null,
      color: "#1976D2", // default event color
      selectedEvent: {
        start: null,
        end: null,
        title: null,
        description: null,
        type: {
          title: null,
          color: null,
          id: null,
        },
        location: {
          name: null,
        },
        duration: {
          start: {
            timestamp: null,
            isOpen: false,
          },
          end: {
            timestamp: null,
            isOpen: false,
          },
        },
        status: null,
        meta: {
          id: null,
        },
      },
      selectedElement: null,
      selectedOpen: false,
      calShortMonths: false,
      calShortWeekdays: false,
    };
  },
  computed: {
    //   organization() {
    //     return this.$store.state.organization.activeOrganization;
    //   },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      // this.$store.dispatch(`${MY_ORGANIZATION}${GET_SURVEYS}`, {
      //   organizationId: this.$route.params.organizationId,
      // });
    },
  },
};
</script>
